import React from 'react';
import _ from 'lodash';
import _i from 'immutable-functions';

import API from '../api';
import Email from './Email';
import Contact from './Contact';

// https://medium.com/@everdimension/how-to-handle-forms-with-just-react-ac066c48bd4f

const FormTypes = {
  email: Email,
  contact: Contact,
};

const NoForm = () => <div> No form has been selected </div>;

class Form extends React.PureComponent {
  _isMounted = true;

  state = {
    displayErrors: false,
    submitted: false,
    loading: false,
  };

  constructor(props) {
    super(props);

    this.formSubmit = this.formSubmit.bind(this);
    API.setHostAndProtocol(props.host, props.protocol);
  }

  componentDidMount() {
    this._inStudio =
      window.location !== window.parent.location &&
      window.parent.location.href.indexOf('studio.') > -1;
    this._isMounted = true;

    let {
      item: { settings: { form } = {} },
    } = this.props;
    if (!form) return;

    let { form_id } = form;
    if (!form_id) return;

    this.form_id = form_id;

    _.delay(() => {
      if (!this._isMounted) return;
      this.apiCall = API.getForm(form_id).then(
        form => {
          if (this._isMounted) this.setState({ form });
        },
        e => console.log('API Error', e)
      );
    }, 50);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  formSubmit(event) {
    event.preventDefault();
    this.setState({ displayErrors: false });

    let form = event.target;
    let data = new FormData(form);

    if (!form.checkValidity()) {
      console.log('Form has errors');
      this.setState({ displayErrors: true });
      return;
    }

    if (this._inStudio) {
      let alert_messages = [
        "We won't let you submit a form while you're in the Studio, we just won't do it.",
        'Yes, it works, just not from within the Studio.',
        'If you would like to test out the form submission, you can try it on the live site.',
        "We're really glad you enjoy your form. Try it on the live site to submit.",
      ];
      alert(
        alert_messages[
          Math.floor(Math.random() * Math.floor(alert_messages.length - 1))
        ]
      );
      return;
    }

    this.setState({ loading: true });
    this.apiCall = API.submitForm(this.form_id, data)
      .then(data => {
        _.delay(() => this.setState({ submitted: true, loading: false }), 750);

        console.log('Form Submitted', data);
      })
      .catch(e => {
        console.log('Form Not Submitted', e);
      });
  }

  stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
      data[key] = fd.get(key);
    }
    return JSON.stringify(data, null, 2);
  }

  render() {
    let { form, submitted, displayErrors } = this.state;
    if (!form) {
      return <NoForm />;
    }

    let { form_type } = form;

    const _FormType = FormTypes[form_type];
    return (
      <div
        className={`s-form-container s-form-type-${form_type} ${
          displayErrors ? 'errors' : ''
        }`}
      >
        <div className={`s-form-main ${submitted ? 'submitted' : ''}`}>
          <_FormType
            formSubmit={this.formSubmit}
            {...form}
            loading={this.state.loading}
            submitted={submitted}
          />
        </div>
      </div>
    );
  }
}

export default Form;
